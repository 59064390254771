<template>
  <div class="usermanage">
    <div v-show="loading" class="loading">
      <img src="@/assets/loading.gif" alt="" />
    </div>
    <div v-show="!loading">
      <div>
        <p class="title">用户管理</p>
        <div class="btn">
          <el-button class="usermanage-title-button-add" type="primary" @click="createUser('add')">
            新建用户
          </el-button>
          <el-button
            class="usermanage-title-button-del"
            @click="deleteUser"
            :disabled="selectedData.length === 0"
          >
            删除
          </el-button>
        </div>
      </div>
      <eyao-table
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total-num="dataCount"
        :height="'100%'"
        :height-sty="'85%'"
        :hidden-scroll="true"
        :table-data="tableData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="user_name" label="用户名称"></el-table-column>
        <el-table-column prop="phone" label="手机号">
          <template slot-scope="scope">
            {{ phoneFormat(scope.row.phone) }}
          </template>
        </el-table-column>
        <el-table-column prop="organization" label="组织归属">
          <template slot-scope="scope">
            {{ ORGNIZATION_TYPE[scope.row.organization] }}
          </template>
        </el-table-column>
        <el-table-column prop="role" label="角色">
          <template slot-scope="scope">
            {{ ROLE_TYPE[scope.row.role] }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ USER_STATUS_TYPE[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column width="250" fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="usermanage-table-action">
              <el-button type="text" class="usermanage-table-button" @click="editUser(scope.row)">
                编辑
              </el-button>
              <el-button
                :disabled="!scope.row.uin"
                type="text"
                :class="'usermanage-table-menu-item'"
                @click.native="gotoLoginLog(scope.row)"
              >
                登录日志
              </el-button>
              <el-button
                :disabled="!scope.row.uin"
                type="text"
                :class="'usermanage-table-menu-item'"
                @click.native="gotoActionLog(scope.row)"
              >
                操作日志
              </el-button>
            </div>
          </template>
        </el-table-column>
        <template v-slot:empty>
          <div class="no_data" v-if="!tableData.length && !loading">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>
      <!-- <template v-if="!tableData.length && !loading">
        <div class="no_data">
          <img
            src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
            alt="未查询到相关数据"
          />
          未查询到相关数据
        </div>
      </template> -->
      <!-- 创建用户 -->
      <el-drawer
        :title="drawerTitle"
        :visible.sync="drawer"
        direction="rtl"
        destroy-on-close
        custom-class="drawer"
        size="480px"
      >
        <div class="usercreate" v-if="drawer">
          <el-form
            ref="form"
            :model="formData"
            :rules="formRules"
            label-width="80px"
            label-position="left"
          >
            <el-form-item label="用户名称" prop="username">
              <el-input
                :class="'usercreate-form-item1'"
                v-model="formData.username"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input
                :class="'usercreate-form-item1'"
                maxlength="11"
                v-model="formData.phone"
                @input="resetPhoneError"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="组织归属" prop="organization">
              <el-select
                :class="'usercreate-form-item1'"
                v-model="formData.organization"
                placeholder="请选择活动区域"
              >
                <el-option
                  v-for="item in organizationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="角色" prop="role">
              <el-select
                :class="'usercreate-form-item'"
                v-model="formData.role"
                placeholder="请选择角色"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                :class="'usercreate-form-item'"
                v-model="formData.status"
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="btns">
          <el-button :class="'usercreate-button1'" @click="back">取消</el-button>
          <el-button :class="'usercreate-button'" type="primary" @click="submit">保存</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import eyaoTable from '@/components/table';
import { MdmAdminLogin } from '@/api/user';
import { ROLE_TYPE, USER_STATUS_TYPE, ORGNIZATION_TYPE, PHONE_REG } from '@/utils/constant';
import {
  GetMdmUserList,
  MdmAdminDeleteUser,
  MdmAdminCreateUser,
  MdmAdminEditUser,
} from '@/api/userList';
export default {
  name: 'UserManage',
  components: {
    eyaoTable,
  },
  data() {
    return {
      drawer: false,
      drawerTitle: '',
      tableData: [],
      // 数据总量
      dataCount: 0,
      // 数据当前加载完成的页码
      dataIndex: 1,
      // 已经选择的数据
      selectedData: [],
      // 加载中标识
      loading: true,
      // 渲染表格的选项列表
      ROLE_TYPE,
      USER_STATUS_TYPE,
      ORGNIZATION_TYPE,

      pageSize: '',
      pageIndex: 1,
      pageSizes: [],

      formData: {
        username: '',
        phone: '',
        organization: '腾讯',
        role: 1,
        status: 1,
      },
      formRules: {
        username: [{ required: true, message: '用户名称不能输入为空' }],
        phone: [{ required: true, validator: this.validatePhone }],
        role: [{ required: true, message: '请输入角色' }],
        status: [{ required: true, message: '请输入状态' }],
      },
      // 手机号重复时将错误记录在这里供表单校验函数确认
      phoneError: null,
      // 组织列表
      organizationList: [],
      // 角色列表
      roleList: [],
      // 状态列表
      statusList: [],
      creatType: '',
      odlPhone: '',
    };
  },
  computed: {
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    // 是否继续触发加载事件
    needLoading() {
      return !(this.tableData.length === this.dataCount);
    },
  },
  watch: {
    role(newval) {
      if (newval !== 1) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
  },
  created() {
    this.pageSize = this.g_pageSize;
    this.pageSizes = this.g_pageSizes;
  },
  mounted() {
    if (this.role !== 1) {
      this.$message.error('无权限进入');
      this.$router.push('/dashboard/home');
    }

    this.init();
    this.creatInit();
    store.commit('user/setBreadcrumb', [{ name: '用户管理', path: '/dashboard/user-manage' }]);
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.init();
    },
    init() {
      this.loading = true;
      const req = {
        index: this.pageIndex,
        num: this.pageSize,
      };
      GetMdmUserList(req)
        .then(res => {
          this.dataCount = res.total_num || 0;
          this.tableData = res.user_info_list || [];
          this.dataIndex = 2;
          this.loading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
          this.loading = false;
        });
    },
    // 遮掩电话号码的中间四位数
    phoneFormat(phone) {
      const str = phone.toString().split('');
      str.splice(3, 4, '****');
      return str.join('');
    },
    editUser(item) {
      // 带参数进入 编辑模式
      // this.$router.push({ name: 'user-create', params: { data: item } });
      this.creatType = 'edit';
      this.drawerTitle = '编辑用户';
      this.odlPhone = item.phone;
      this.drawer = true;
      this.formData = {
        username: item.user_name,
        phone: item.phone,
        organization: item.organization,
        role: item.role,
        status: item.status,
      };
    },
    // 不带参数进入 新建模式
    createUser(type) {
      // console.log(type);
      this.creatType = type;
      // this.$router.push('/dashboard/user-create');
      this.drawerTitle = '新建用户';
      this.drawer = true;
      this.formData = {
        username: '',
        phone: '',
        organization: '腾讯',
        role: 1,
        status: 1,
      };
    },
    deleteUser() {
      this.$confirm('确定删除用户吗？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        console.log(this.selectedData);

        // 对已选择数据进行格式化
        const userInfoArray = [];
        this.selectedData.forEach(item => {
          userInfoArray.push({ phone: item.phone, uin: item.uin });
        });

        MdmAdminDeleteUser({ user_list: userInfoArray })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            // 重新加载数据
            this.selectedData = [];
            this.init();
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.selectedData = val;
    },
    // dataLoading() {
    //   // loding控制不重复发送加载请求
    //   if (this.dataCount !== this.tableData.length && this.loading !== false) {
    //     return;
    //   }
    //   this.loading = true;
    //   GetMdmUserList({ index: this.dataIndex, num: this.g_pageSize }).then((res) => {
    //     this.loading = false;
    //     this.dataCount = res.total_num;
    //     // 直接将新增数据放在最后面
    //     this.tableData = this.tableData.concat(res.UserInfoList);
    //     this.dataIndex += 1;
    //   });
    // },
    // 带上用户uin跳转到日志页 日志页根据uin获取相应用户的数据
    gotoLoginLog(row) {
      this.$router.push({ name: 'login-log', params: { uin: row.uin, name: row.user_name } });
    },
    gotoActionLog(row) {
      this.$router.push({ name: 'action-log', params: { uin: row.uin, name: row.user_name } });
    },

    creatInit() {
      Object.keys(ROLE_TYPE).forEach(key => {
        this.roleList.push({ label: ROLE_TYPE[key], value: Number(key) });
      });
      Object.keys(USER_STATUS_TYPE).forEach(key => {
        this.statusList.push({ label: USER_STATUS_TYPE[key], value: Number(key) });
      });
      Object.keys(ORGNIZATION_TYPE).forEach(key => {
        this.organizationList.push({ label: ORGNIZATION_TYPE[key], value: key });
      });
    },
    // 为手机号返回不同报错信息
    validatePhone(rule, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能输入为空'));
      } else if (!PHONE_REG.test(value)) {
        callback(new Error('手机号输入错误'));
      } else if (this.phoneError !== null) {
        callback(new Error('手机号已存在请重新输入'));
      } else {
        callback();
      }
    },
    // 重置手机号重复错误的报错状态
    resetPhoneError() {
      if (this.phoneError !== null) this.phoneError = null;
    },
    submit() {
      this.phoneError = null;
      // 保证表单数据正确后再进行操作
      this.$refs.form.validate(valid => {
        if (valid) {
          // 决定编辑和新建选择哪个操作
          (this.creatType === 'edit'
            ? MdmAdminEditUser({
                user_name: this.formData.username,
                old_phone: this.odlPhone,
                new_phone: this.formData.phone,
                organization: this.formData.organization,
                role: this.formData.role,
                status: this.formData.status,
              })
            : MdmAdminCreateUser({
                user_name: this.formData.username,
                phone: this.formData.phone,
                organization: this.formData.organization,
                role: this.formData.role,
                status: this.formData.status,
              })
          )
            .then(() => {
              this.$message.success('保存成功');
              this.drawer = false;
              this.init();
              // 重新登陆一次 确认当前用户的状态是否改变 比如执行了修改自身角色或冻结自身账号的操作
              MdmAdminLogin()
                .then(res => {
                  if (res.menu_list?.length === 0) {
                    this.$router.push('/login');
                  } else {
                    store.commit('user/setRole', res.role);
                  }
                })
                .catch(e => {
                  if (e.payload.menu_list === undefined || e.payload.menu_list?.length === 0) {
                    this.$router.push('/login');
                  }
                });

              // this.$router.push('user-manage');
            })
            .catch(err => {
              if (err.bizcode !== 0) {
                // 获取手机号重复错误来触发表单验证
                this.phoneError = err;
                this.$refs.form.validate(valid => {
                  if (valid) {
                    console.log('formcheck success');
                  } else {
                    return false;
                  }
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    back() {
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './user-manage.scss';
@import './user-create.scss';
</style>
